import { showNotification } from "@/lib/helpers";
import User from "@/models/User";
import { makeAutoObservable } from "mobx";

export class AuthStore {
  constructor({ apiStore }) {
    makeAutoObservable(this);
    this.apiStore = apiStore;
  }

  state = "fetching"; // fetching, loggedIn, loggedOut, error

  setState(value) {
    this.state = value;
  }

  authStatus = "login"; // generalCta, goCta, articleCta, login, signUp, forgotPassword, setPassword

  setAuthStatus(value) {
    this.authStatus = value;
  }

  isAuthActive = false;

  setIsAuthActive(value) {
    this.isAuthActive = value;
  }

  isClosable = true;

  setIsClosable(value) {
    this.isClosable = value;
  }

  ctaActivated = false;

  setCtaActivated(value) {
    this.ctaActivated = value;
  }

  modalContent = {
    generalCta: {
      heading: "Welcome to the new Mob website. You can...",
    },
    activateRecipeCta: {
      heading: "Let's get started!",
    },
    articleCta: {
      heading:
        "Hey there! Welcome to the new Mob website. Sign up to continue reading (it's free).",
    },
    login: {
      heading: "Log in to your Mob account",
      headerContent:
        "Log in to your account below to save recipes, shopping lists and more.",
    },
    signUp: {
      heading: "Sign Up",
      headerContent:
        "Create an account with us to save recipes, shopping lists and more.",
    },
    forgottenPassword: {
      heading: "Forgotten your password?",
      headerContent:
        "Enter your email address and we will send you instructions on how to reset your password",
    },
  };

  currentUser = null;

  setCurrentUser(value) {
    this.currentUser = value;
  }

  get isReady() {
    return this.state !== "fetching";
  }

  get isLoggedIn() {
    return !!this.currentUser;
  }

  get isLoggedOut() {
    return !this.isLoggedIn;
  }

  fetchCurrentUser = () => {
    this.setState("fetching");

    return this.apiStore
      .axiosInstance({
        method: "GET",
        url: "currentUser.json",
      })
      .then(
        (response) => {
          if (response.status === 200 && response.data) {
            const user = new User(this, response.data);
            this.setCurrentUser(user);
            this.setState("loggedIn");

            // Now we're logged in, get the csrf token if one doesn't exist
            if (!this.apiStore.isReady) this.apiStore.fetchCsrfToken();

            return user; // Return the user object
          } else {
            this.setCurrentUser(null);
            this.setState("loggedOut");
            return null; // Return null if no user data
          }
        },
        (error) => {
          this.setCurrentUser(null);
          this.setState("loggedOut");
          throw error; // Throw the error to be caught by the caller
        }
      );
  };

  // Sign Up
  signUp = async ({ firstName, lastName, email, password }) => {
    // Fetch a current csrf token
    if (!this.apiStore.isReady) await this.apiStore.fetchCsrfToken();

    try {
      const signUpResponse = await this.apiStore.axiosInstance({
        method: "POST",
        data: {
          firstName,
          lastName,
          email,
          password,
          fields: {
            plan: "3", // Since Mob+ launch we've launched a 3rd plan which is the new free but without menus, shopping list, etc. features
            listsMigrated: "true", // Mark lists as migrated by default
          },
          action: "users/save-user",
        },
      });

      if (signUpResponse.data.success) {
        return {
          success: true,
          id: signUpResponse.data.id,
        };
      }
    } catch (error) {
      return {
        success: false,
        error:
          error.response.data.error ||
          "An unexpected error occurred during sign up.",
      };
    }
  };

  // Login
  login = async ({ loginName, password, rememberMe = false }) => {
    // Fetch a current csrf token
    if (!this.apiStore.isReady) await this.apiStore.fetchCsrfToken();

    try {
      const signInResponse = await this.apiStore.axiosInstance({
        method: "POST",
        data: {
          loginName,
          password,
          rememberMe: rememberMe ? 1 : 0,
          action: "users/login",
        },
      });

      if (signInResponse.data.success) {
        // Set the new csrf token value
        if (signInResponse.data.csrfTokenValue)
          this.apiStore.setCsrfTokenValue(signInResponse.data.csrfTokenValue);

        // Fetch current user to update state and return additional data
        return this.fetchCurrentUser().then(() => ({
          success: true,
          user: this.currentUser,
        }));
      }
      if (signInResponse.data.error) {
        return {
          success: false,
          error:
            signInResponse.data.error || "Login failed with no specific error.",
        };
      }
    } catch (error) {
      showNotification(
        `There was a problem logging you in: ${error.message || error}`
      );
      return {
        success: false,
        error: error.message || "An unexpected error occurred during login.",
      };
    }
  };

  // Logout
  logout = async () => {
    // Fetch a current csrf token
    if (!this.apiStore.isReady) await this.apiStore.fetchCsrfToken();

    const data = {
      action: "users/logout",
    };

    try {
      const signOutResponse = await this.apiStore.axiosInstance({
        method: "POST",
        data,
      });

      if (signOutResponse.data.success) {
        this.setState("loggedOut");
        this.setCurrentUser(null);

        // Set the new csrf token value
        if (signOutResponse.data.csrfTokenValue)
          this.apiStore.setCsrfTokenValue(signOutResponse.data.csrfTokenValue);

        return { success: true };
      } else {
        showNotification(
          `There was a problem logging you out: ${
            signOutResponse.data.error || "Unknown error"
          }`
        );
        return {
          success: false,
          error:
            signOutResponse.data.error ||
            "Logout failed with no specific error.",
        };
      }
    } catch (error) {
      showNotification(
        `There was a problem logging you out: ${error.message || error}`
      );

      return {
        success: false,
        error: error.message || "An unexpected error occurred during logout.",
      };
    }
  };

  // Forgotten password
  forgottenPassword = async ({ emailToReset }) => {
    await this.apiStore.fetchAuthServiceBearerToken();

    try {
      const forgottenPasswordResponse =
        await this.apiStore.userServiceAxiosInstance(
          "/api/v1/password/request-reset",
          {
            method: "POST",
            data: {
              emailToReset,
            },
          }
        );

      if (forgottenPasswordResponse.data.error) {
        return {
          success: false,
          error: forgottenPasswordResponse.data.error,
        };
      }

      return {
        success: true,
      };
    } catch (error) {
      showNotification(`There was a problem: ${error}`);

      return {
        success: false,
        error:
          error.response.data.error ||
          "An unexpected error occurred during password reset.",
      };
    }
  };

  // Set password
  setPassword = async (accessToken = {}, { newPassword }) => {
    // Fetch a current csrf token
    await this.apiStore.fetchAuthServiceBearerToken(accessToken);

    try {
      const setPasswordResponse = await this.apiStore.userServiceAxiosInstance(
        "/api/v1/password/reset",
        {
          method: "POST",
          data: {
            newPassword,
          },
        }
      );

      if (setPasswordResponse.data.error) {
        return {
          success: false,
          error: `There was a problem setting your new password: ${setPasswordResponse.data.error}`,
        };
      }

      return {
        success: true,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error:
          error.response?.data?.error ||
          "An unexpected error occurred during password reset.",
      };
    }
  };

  setPasswordLegacy = async ({ newPassword, legacyUid }) => {
    // Fetch a current csrf token
    await this.apiStore.fetchAuthServiceBearerToken();

    try {
      const setPasswordResponse = await this.apiStore.userServiceAxiosInstance(
        "/api/v1/password/reset-legacy",
        {
          method: "POST",
          data: {
            newPassword,
            legacyUid,
          },
        }
      );

      if (setPasswordResponse.data.error) {
        return {
          success: false,
          error: `There was a problem setting your new password: ${setPasswordResponse.data.error}`,
        };
      }

      return {
        success: true,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        error:
          error.response?.data?.error ||
          "An unexpected error occurred during password reset.",
      };
    }
  };
}
