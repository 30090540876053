import axios from "axios";
import { makeAutoObservable } from "mobx";
import { stringify } from "qs";
import { decodeJwt } from "jose";

export class APIStore {
  constructor() {
    makeAutoObservable(this);
  }

  csrfTokenName = null;
  authServiceBearerToken = null;

  setCsrfTokenName = (value) => {
    this.csrfTokenName = value;
  };

  csrfTokenValue = null;

  setCsrfTokenValue = (value) => {
    this.csrfTokenValue = value;
  };

  get isReady() {
    return !!this.csrfTokenValue;
  }

  fetchCsrfToken = () => {
    const csrfResponse = this.axiosInstance({
      method: "GET",
      url: "/actions/site-module/site/get-csrf",
    })
      .then((response) => {
        if (response.status === 200 && response.data) {
          this.setCsrfTokenName(response.data.name);
          this.setCsrfTokenValue(response.data.value);
        } else {
          return Promise.reject(response.data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {});

    return csrfResponse;
  };

  fetchAuthServiceBearerToken = async (accessToken) => {
    if (this.authServiceBearerToken) {
      // if no accessToken is passed we can return the existing token
      if (!accessToken) {
        return this.authServiceBearerToken;
      }

      // decode the bearer token
      const decodedToken = decodeJwt(this.authServiceBearerToken);

      // if the token exists and accessTokens match then return the token
      if (decodedToken && decodedToken.accessToken === accessToken) {
        return this.authServiceBearerToken;
      }
    }

    const response = await axios.get(
      `/api/auth/generate-auth-service-jwt${
        accessToken ? `?accessToken=${accessToken}` : ""
      }`
    );

    this.authServiceBearerToken = response.data.token;

    return this.authServiceBearerToken;
  };

  get axiosInstance() {
    const client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_CMS_URL,
      crossDomain: true,
      withCredentials: true,
      maxRedirects: 0,
      transformRequest: [
        (data) =>
          stringify({
            [this.csrfTokenName]: this.csrfTokenValue,
            ...data,
          }),
      ],
    });

    return client;
  }

  get authServiceAxiosInstance() {
    const client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_AUTH_SERVICE_URL,
      crossDomain: true,
      withCredentials: true,
      maxRedirects: 0,
      headers: {
        Authorization: `Bearer ${this.authServiceBearerToken}`,
      },
    });

    return client;
  }

  get userServiceAxiosInstance() {
    const client = axios.create({
      baseURL: process.env.NEXT_PUBLIC_USER_SERVICE_URL,
      crossDomain: true,
      withCredentials: true,
      maxRedirects: 0,
      headers: {
        Authorization: `Bearer ${this.authServiceBearerToken}`,
      },
    });

    return client;
  }
}
